<template>
    <b-container v-if="product">
        <b-row>
            <b-col>
                <h3 class="mt-3">{{ product.id ? '修改商品' : '建立商品' }}</h3>
            </b-col>
            <b-col v-if="product.id">
                <b-button class="mt-3 float-right" variant="danger" @click="onDelete">刪除商品</b-button>
            </b-col>
        </b-row>

        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="名稱">
                <b-form-input v-model="product.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="短稱 (打印名稱)">
                <b-form-input v-model="product.shortName"></b-form-input>
            </b-form-group>
            <b-form-group label="說明">
                <b-form-input v-model="product.description"></b-form-input>
            </b-form-group>

            <h5>價錢</h5>
            <b-row>
                <b-col md="6">
                    <b-form-group label="自取價錢">
                        <b-input-group prepend="$">
                            <b-form-input v-model.number="product.price.PICKUP" type="number" step="0.1" required></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="外送價錢">
                        <b-input-group prepend="$">
                            <b-form-input v-model.number="product.price.DELIVERY" type="number" step="0.1" required></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <h5>選項分類</h5>
            <b-form-group label="可選選項分類">
                <n-multiselect label="name" :options="extras" :reset-after="true" @select="addExtra"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="product.extras.length > 0">
                <n-draggable class="list-group" :list="product.extras">
                    <b-list-group-item v-for="(extra, index) in product.extras" :key="index">
                        <b-row>
                            <b-col class="pt-2">{{ extra.name }}</b-col>
                            <b-col cols="4">
                                <b-button variant="danger" block @click="removeExtra(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </n-draggable>
            </b-form-group>

            <h5>圖片</h5>
            <b-form-group label="上傳圖片">
                <b-form-file v-model="newImage" accept="image/jpeg, image/png" />
                <b-card v-if="product.image" class="mt-3" :img-src="product.image">
                    <b-button variant="danger" block @click="removeImage()">刪除</b-button>
                </b-card>
            </b-form-group>

            <h5>分店</h5>
            <b-form-group label="停售分店">
                <n-multiselect label="name" :options="shops" :reset-after="true" @select="addShopId"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="productSoldOutAtShops.length > 0">
                <b-list-group>
                    <b-list-group-item v-for="(shop, index) in productSoldOutAtShops" :key="index">
                        <b-row>
                            <b-col>{{ shop.name }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="mt-3" md="12" lg="4">
                                <b-button variant="danger" block @click="removeShopId(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { Product } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            product: null,
            newImage: null,
        }
    },

    async created() {
        this.product = this.action == 'create' ? Product() : await this.$store.dispatch('getDocument', { col: 'products', id: this.$route.params.id })
        if (!this.product) return this.$router.push('/products')
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'shops', merchantId: localStorage.merchantId })
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'extras', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            shops: state => state.shops,
            extras: state => state.extras,
        }),

        productSoldOutAtShops() {
            let ret = []
            this.product.soldOutAtShopIds.forEach(id => {
                ret.push(this.shops.find(shop => shop.id == id))
            })
            return ret
        },
    },

    methods: {
        addExtra(obj) {
            if (!this.product.extras.find((extra) => extra.id == obj.id)) return this.product.extras.push(Object.assign({}, obj))
        },

        async removeExtra(index) {
            try{
                this.product.extras.splice(index, 1)
                if (this.action == 'create') return 
                await this.$store.dispatch('updateDocument', { col: 'products', doc: this.product })
                this.$root.$bvToast.toast('成功刪除',{title:'刪除成功',variant:'success'})
            } catch (error) {
                this.$root.$bvToast.toast(error,{title:'刪除錯誤',variant:'danger'})
            }
        },

        removeImage() {
            return this.product.image = null
        },

        addShopId(obj) {
            if (!this.product.soldOutAtShopIds.find(id => id == obj.id)) return this.product.soldOutAtShopIds.push(obj.id)
        },

        async removeShopId(index) {
            try{
                this.product.soldOutAtShopIds.splice(index, 1)
                if (this.action == 'create') return 
                await this.$store.dispatch('updateDocument', { col: 'products', doc: this.product })
                this.$root.$bvToast.toast('成功刪除',{title:'刪除成功',variant:'success'})
            } catch (error) {
                this.$root.$bvToast.toast(error,{title:'刪除錯誤',variant:'danger'})
            }
        },

        async onDelete() {
            const res = await this.$store.dispatch('deleteDocument', { col: 'products', id: this.product.id })
            this.$root.$bvToast.toast(`刪除${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/products')
        },

        async onSubmit() {
            this.product.merchantId = localStorage.merchantId

            let res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'products', doc: this.product })
            this.product.id = res.id
            if (this.newImage) {
                this.product.image = await this.$store.dispatch('createStorage', { col: 'products', id: this.product.id, file: this.newImage })
                res = await this.$store.dispatch('updateDocument', { col: 'products', doc: this.product })
            }
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' : '失敗'}`, { title: res ? '成功' : '失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/products')
        },
    },
}
</script>
